import React, { FC } from 'react';
import AuthLoading from '../../../components/AuthLoading/AuthLoading';
import Layout from '../../../components/Layout/Layout';
import Seo from '../../../components/Seo/Seo';
import AnalyticsHeaders from '../BranchView/AnalyticsHeaders';

const FormsView: FC<{ pageContext: { branch: Queries.CollarAPI_Branch } }> = ({ pageContext }) => {
  const { branch } = pageContext;
  const inWindow = typeof window !== 'undefined';
  const name = branch?.name;
  return (
    <Layout>
      {inWindow ? (
        <AuthLoading branch={branch} isFormFill />
      ) : (
        <div style={{ opacity: 0 }}>
          <span>Book services from {name}, manage your pets data and connect with pet owners and businesses all while staying on top of your pets health and wellness.</span>
        </div>
      )}
    </Layout>
  );
};

export const Head = ({ pageContext, data }) => {
  const { branch } = pageContext;
  const name = branch?.name;
  const logo = branch?.photos?.logo;
  const analytics = branch?.analytics;
  return (
    <Seo
      title={name}
      description={`Book services from ${name}, manage your pets data and connect
        with pet owners and businesses all while staying on top of your pets
        health and wellness.`}
      image={logo}
    >
      <AnalyticsHeaders analytics={analytics} />
    </Seo>
  );
};

export default FormsView;
